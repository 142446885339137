body {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}

.signup-container {
    background-color: #fff;
    border-radius: 10px;
    width: 100%;
    max-width: 400px; /* Limit width to 400px for larger screens */
    text-align: center;
}

h2 {
    color: #333;
    margin: 0;
}

input[type="text"],
input[type="password"],
select {
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
}

button[type="submit"] {
    background: linear-gradient(to right, red, #ffd000);
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

button[type="submit"]:hover {
    background-color: #45a049;
}

.home-btn {
    font-size: 24px;
    color: #333;
}

.home-icon {
    font-size: 1.5rem;
    color: #333;
}

/* Responsive design */
@media (max-width: 768px) {
    .signup-container {
        padding: 20px;
    }

    h2 {
        font-size: 24px;
    }

    button[type="submit"] {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    .home-btn {
        font-size: 18px;
    }

    h2 {
        font-size: 22px;
    }

    button[type="submit"] {
        font-size: 12px;
    }
}
