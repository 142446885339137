/* Sidebar Styles */
.sidebar {
    width: 250px;
    background-color: #333;
    color: #fff;
    height: 100vh;
    padding: 20px;
    position: fixed; /* Fixed positioning */
    top: 0;
    left: 0;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
    z-index: 999; /* Ensure sidebar is above other elements */
}

/* Toggle Button Styles */
.toggle-button {
    font-size: 24px;
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
    position: fixed;
    top: 20px;
    left: 20px; /* Position toggle button on the left for mobile */
    z-index: 1000; /* Ensure toggle button is above the sidebar */
    display: none; /* Hidden by default */
}

/* Show toggle button on mobile */
@media (max-width: 768px) {
    .toggle-button {
        display: block;
    }

    .sidebar {
        transform: translateX(-100%); /* Hidden by default on mobile */
    }

    .sidebar.open {
        transform: translateX(0); /* Show when open */
    }
}

/* Always show sidebar on desktop */
@media (min-width: 769px) {
    .sidebar {
        transform: translateX(0); /* Always visible */
    }

    .toggle-button {
        display: none; /* Hide toggle button on desktop */
    }
}

/* Link Styles */
.link {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    color: #fff;
    text-decoration: none;
    transition: background-color 0.3s;
}

.link:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.link-icon {
    margin-right: 10px;
}

.logo {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
